.swiper-pagination-bullet {
    width: 10px;
    background-color: white;
    border-radius: 0% !important;
    height: 10px !important;
    border-radius: 50%;

} 


  