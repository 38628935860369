@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;






@layer utilities {
  .bg-gradiet-1 {
    background: var(--gradiet-1, linear-gradient( #91303F 21.96%, #C07571 75.76%, #E28E7C 88.37%, #E0B497 100%));
  }

  /* Global CSS dosyası (örneğin: styles.css) */
.text-gradient {
  background: linear-gradient(99deg, #060606 3.05%, #91303F 21.96%, #C07571 75.76%, #E28E7C 88.37%, #E0B497 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.text2-gradient {
  background: linear-gradient(99deg, #ce485c 21.96%, #C07571 75.76%, #E28E7C 88.37%, #E0B497 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.gradient-border-button {
  font-size: 16px;
  color: white;
  background-color: transparent; /* İç kısım şeffaf */
  border: 2px solid transparent; /* Border için temel şeffaf */
  border-radius: 20px; /* Köşeleri yuvarlama */
  background-image: linear-gradient(#4C2828, #4C2828), 
    linear-gradient(99deg, #060606 11.17%, #91303F 23.36%, #C07571 58.07%, #E28E7C 66.2%, #E0B497 73.7%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  width: 148px;
  height: 44px;
  transition: background-image 300ms ease-in-out, transform 300ms ease-in-out;
}

.gradient-border-button:hover {
  background-image: linear-gradient(100deg, #060606 -46.67%, #91303F 25.17%, #C07571 62.59%, #E28E7C 82.04%, #E0B497 100%);
}

.gradient-border-button2 {
  font-size: 16px;
  color: white;
  background-color: transparent; /* İç kısım şeffaf */
  border: 2px solid transparent; /* Border için temel şeffaf */
  border-radius: 20px; /* Köşeleri yuvarlama */
  background-image: linear-gradient(black, black), 
    linear-gradient(99deg, #060606 1.17%, #91303F 23.36%, #C07571 58.07%, #E28E7C 66.2%, #E0B497 73.7%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  width: 148px;
  height: 44px;
}

.gradient-border-button2:hover {
  background-image: linear-gradient(100deg, #060606 -46.67%, #91303F 25.17%, #C07571 62.59%, #E28E7C 82.04%, #E0B497 100%);
}









  
  .blur-300px {
    filter: blur(150px);
  }
  .text-bg {
    background-color: rgba(0, 0, 0, 0.5); /* Yarı saydam arka plan */
    padding: 10px; /* İç boşluk */
    border-radius: 10px; /* Kenarları yuvarlatma */
  }
}


@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}
* {
  font-family: "Montserrat", serif;
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: rgb(241,89,41);
  font-weight: 600;
  text-decoration: 1px underline rgb(241,89,41);
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
