.swiper-button-next {
    background: linear-gradient(99deg, #060606 3.05%, #91303F 21.96%, #C07571 75.76%, #E28E7C 88.37%, #E0B497 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}
.swiper-button-prev {
    background: linear-gradient(99deg, #060606 3.05%, #91303F 21.96%, #C07571 75.76%, #E28E7C 88.37%, #E0B497 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

.swiper-pagination {
    display: none !important;
}